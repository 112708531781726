import React from "react";
import AppLayout from "./../../layout/Layout";
import { Button, Col, Row, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const Staff = () => {
  return (
    <AppLayout>
      <Row gutter={[12, 20]}>
        <Col span={12} offset={12} style={{ textAlign: "end" }}>
          <Button type="primary">
            Add Staff <PlusOutlined />
          </Button>
        </Col>

        <Col span={24}>
          <Table pagination={false} />
        </Col>
      </Row>
    </AppLayout>
  );
};

export default Staff;
