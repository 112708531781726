import React from "react";
import { Layout, Menu, Avatar, Typography, Row, Col } from "antd";
import {
  DatabaseOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import axios from "axios";

const { Header, Sider, Content } = Layout;

const AppLayout = ({ children }) => {
  const navigate = useNavigate();

  const handleOrders = () => {
    navigate("/orders");
  };

  const handleStaff = () => {
    navigate("/staff");
  };

  const handleLogout = async () => {
    try {
      await axios.post("/logout"); // Make sure the URL matches your logout route
      // Handle successful logout (e.g., redirect to login page or show a message)
      window.location.href = "/login"; // Redirect to login page
    } catch (error) {
      console.error("Logout failed:", error);
      // Handle logout error
    }
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{
          background: "#fff",
          padding: "0 16px",
          height: "60px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Row
          style={{
            width: "100%",
          }}
          justify="space-between"
          align="middle"
        >
          <Col>
            <Typography.Title level={5} style={{ margin: 0 }}>
              Pro Display Solutions
            </Typography.Title>
          </Col>
          <Col>
            <Avatar
              size={35}
              icon={<UserOutlined />}
              style={{ cursor: "pointer" }}
            />
          </Col>
        </Row>
      </Header>

      <Layout>
        <Sider
          width={200}
          style={{
            background: "#fff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Menu mode="inline" style={{ borderRight: 0 }}>
            <Menu.Item
              key="1"
              onClick={handleOrders}
              icon={<DatabaseOutlined />}
            >
              Orders
            </Menu.Item>
            <Menu.Item key="2" onClick={handleStaff} icon={<TeamOutlined />}>
              Staff
            </Menu.Item>
          </Menu>
        </Sider>

        <Content style={{ padding: 24, margin: 0, minHeight: 280 }}>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
