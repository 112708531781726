import React from "react";
import { Row, Button, Form, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Signup = () => {
  const navigate = useNavigate();

  const onFinish = async (values) => {
    // try {
    //   const response = await axios.post("http://localhost:5000/signup", {
    //     email: values.email,
    //     password: values.password,
    //     passwordConfirm: values.passwordConfirm,
    //   });
    //   if (response.data.status === "success") {
    //     message.success("Signup successful!");
    //     navigate("/login");
    //   } else {
    //     message.error("Signup failed. Please try again.");
    //   }
    // } catch (error) {
    //   message.error(error.response?.data?.message || "Signup failed. Please try again.");
    // }
  };

  return (
    <Row
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Form
        name="signup"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
            {
              type: "email",
              message: "Please enter a valid email!",
            },
          ]}
        >
          <Input placeholder="Enter your email" style={{ width: "300px" }} />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
            {
              min: 8,
              message: "Password must be at least 8 characters!",
            },
          ]}
        >
          <Input.Password
            placeholder="Enter your password"
            style={{ width: "300px" }}
          />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="passwordConfirm"
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Passwords do not match!"));
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="Confirm your password"
            style={{ width: "300px" }}
          />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Sign Up
          </Button>
        </Form.Item>
      </Form>
    </Row>
  );
};

export default Signup;
