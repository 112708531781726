import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import ProjectRoutes from "./routes/Routes";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ProjectRoutes />
    </QueryClientProvider>
  );
}

export default App;
