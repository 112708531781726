import React from "react";
import { Row, Button, Form, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Login = () => {
  const navigate = useNavigate();

  const defaultEmail = "admin@gmail.com";
  const defaultPassword = "admin1234";

  const onFinish = async (values) => {
    try {
      if (
        values.email === defaultEmail &&
        values.password === defaultPassword
      ) {
        message.success("Logged in with default credentials!");
        navigate("/orders");
      } else {
        const response = await axios.post("https://api.prodisplaysolution.com/login", {
          email: values.email,
          password: values.password,
        });

        if (response.data.status === "Success") {
          message.success("Login successful!");
          navigate("/orders");
        } else {
          message.error("Login failed. Please try again.");
        }
      }
    } catch (error) {
      message.error(
        error.response?.data?.message || "Login failed. Please try again."
      );
    }
  };

  return (
    <Row
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundImage: 'url("/BG.svg")',
      }}
    >
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input placeholder="Enter your email" style={{ width: "300px" }} />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password
            placeholder="Enter your password"
            style={{ width: "300px" }}
          />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Row>
  );
};

export default Login;
