import { Button, Col, Row, Form, Input, notification } from "antd";
import React from "react";
import axios from "axios";

const CreateOrder = ({ fetchOrders, setIsModalVisible }) => {
  const [form] = Form.useForm();

  const handleCreateOrder = async (values) => {
    try {
      await axios.post(
        "https://api.prodisplaysolution.com/api/crm/orders/createCustomer",
        values
      );

      notification.success({
        message: "Success",
        description: "Order created successfully",
      });
      fetchOrders(); // Refresh the order list
      setIsModalVisible(false); // Close the modal
      form.resetFields(); // Reset form fields
    } catch (error) {
      console.error("Error creating order:", error);
      notification.error({
        message: "Error",
        description: "There was an error creating the order",
      });
    }
  };

  return (
    <Row>
      {" "}
      <Form form={form} layout="vertical" onFinish={handleCreateOrder}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="name" label="Name">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["address", "pincode"]} label="Pincode">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name={["address", "city"]} label="City">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["address", "state"]} label="State">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name={["address", "country"]} label="Country">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="phoneNumber" label="Phone Number">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="productName" label="Product Name">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="productModel" label="Product Model">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Row>
  );
};

export default CreateOrder;
