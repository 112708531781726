import React, { useState, useEffect } from "react";
import AppLayout from "./../../layout/Layout";
import {
  Button,
  Col,
  Row,
  Table,
  Modal,
  Form,
  Input,
  notification,
  Popconfirm,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import CreateOrder from "./createOrders";

const Order = () => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [orders, setOrders] = useState([]);
  const [editOrderId, setEditOrderId] = useState(null);

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const response = await axios.get(
        "https://api.prodisplaysolution.com/api/crm/orders/listOrders"
      );
      setOrders(response.data.data.allOrders);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const handleUpdateOrder = async (values) => {
    try {
      await axios.put(
        `https://api.prodisplaysolution.com/api/crm/orders//update/${editOrderId}`,
        values
      );
      notification.success({
        message: "Success",
        description: "Order updated successfully",
      });
      fetchOrders(); // Refresh the order list
      setIsEditModalVisible(false); // Close the edit modal
      form.resetFields(); // Reset form fields
    } catch (error) {
      console.error("Error updating order:", error);
      notification.error({
        message: "Error",
        description: "There was an error updating the order",
      });
    }
  };

  const handleDeleteOrder = async (id) => {
    try {
      await axios.delete(`https://api.prodisplaysolution.com/api/crm/orders/delete/${id}`);
      notification.success({
        message: "Success",
        description: "Order deleted successfully",
      });
      fetchOrders(); // Refresh the order list
    } catch (error) {
      console.error("Error deleting order:", error);
      notification.error({
        message: "Error",
        description: "There was an error deleting the order",
      });
    }
  };

  const handleEditClick = (order) => {
    setEditOrderId(order._id);
    form.setFieldsValue(order);
    setIsEditModalVisible(true);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Order ID",
      dataIndex: "orderId",
      key: "orderId",
    },

    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (address) =>
        `${address.city}, ${address.state}, ${address.country} (${address.pincode})`,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Product Model",
      dataIndex: "productModel",
      key: "productModel",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <span>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEditClick(record)}
            style={{ marginRight: 8 }}
          />
          <Popconfirm
            title="Are you sure to delete this order?"
            onConfirm={() => handleDeleteOrder(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<DeleteOutlined />} />
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <AppLayout>
      <Row gutter={[12, 20]}>
        <Col span={12} offset={12} style={{ textAlign: "end" }}>
          <Button type="primary" onClick={() => setIsModalVisible(true)}>
            Create Order <PlusOutlined />
          </Button>
        </Col>

        <Col span={24}>
          <Table
            pagination={false}
            columns={columns}
            dataSource={orders}
            rowKey="_id" // Assuming MongoDB ID is used as the row key
          />
        </Col>
      </Row>

      <Modal
        title="Create Order"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <CreateOrder
          fetchOrders={fetchOrders}
          setIsModalVisible={setIsModalVisible}
        />
      </Modal>

      <Modal
        title="Edit Order"
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleUpdateOrder}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="name" label="Name">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["address", "pincode"]} label="Pincode">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name={["address", "city"]} label="City">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["address", "state"]} label="State">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name={["address", "country"]} label="Country">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="phoneNumber" label="Phone Number">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="email" label="Email">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="productName" label="Product Name">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="productModel" label="Product Model">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </AppLayout>
  );
};

export default Order;
